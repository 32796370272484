import 'babel-polyfill';
import 'script-loader!node_modules/jquery/dist/jquery.min.js';

// uncomment if you need to do expand/collapse:
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js';

import { Global } from 'js/common/global.js';
import { OnReady } from 'js/components/Util/Browser.js';

import { GoogleAnalytics } from 'js/components/GoogleAnalytics/index.js'; /* TODO: remove if unused */
import { LocationHours } from 'js/components/LocationHours/index.js'; /* TODO: remove if unused */



Global.init();
GoogleAnalytics.enableAutotracking('yext');

OnReady(() => {
  LocationHours.loadAndRun();

  // - The un-minified file is different than the minified one :/
  // - Uncomment the following if using JVector map
  // VectorMap.autorun();
});
