import 'script-loader!node_modules/autotrack/autotrack.js';

export class GoogleAnalytics {
  static enableAutotracking(trackerName) {
    const requireTrackName = `${trackerName}.require`

    ga(requireTrackName, 'eventTracker');
    ga(requireTrackName, 'impressionTracker');
    ga(requireTrackName, 'maxScrollTracker');
    ga(requireTrackName, 'outboundFormTracker');
    ga(requireTrackName, 'outboundLinkTracker');
    ga(requireTrackName, 'pageVisibilityTracker');
    ga(requireTrackName, 'urlChangeTracker');
  }
}
