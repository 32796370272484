import { AccessibilityHelpers } from 'js/components/Util/Accessibility.js';
import { Throttle } from 'js/components/Util/Throttle.js';
const createFocusTrap = require('focus-trap/index.js');

let accessibilityHelpersInstance = new AccessibilityHelpers();

export class Header {
  constructor(mobileBP = 767) {
    this.headerEl = document.getElementById('Header');
    this.menuOverlayEl = this.headerEl.querySelector('.Header-overlay');
    this.navEl = this.headerEl.querySelector('.Header-content');
    this.menuEl = this.headerEl.querySelector('.Header-menu--mobile');
    this.menuBtnEl = this.headerEl.querySelector('.Header-toggleIcon');
    this.menuItems = this.headerEl.querySelectorAll('.Header-menuItem a');
    this.hasBeenSetupMobile = false;
    this.mobileBP = window.matchMedia(`(max-width: ${mobileBP}px)`);
    this.wasDesktop = !this.mobileBP.matches;
    this.focusTrap = createFocusTrap('#Header', {
      onActivate: () => {
        this.handleHeaderTabIndexes(0);
      },
      onDeactivate: () => {
        this.handleHeaderTabIndexes(-1);
      },
    });

    this.handleMobileHeader();
    this.mobileBP.addListener(this.handleMobileHeader.bind(this));

    this.makeDesktopHandlers();

    let throttledScrollCollapse = new Throttle('scroll', 'throttledScrollCollapse', window);
    throttledScrollCollapse.start();
    window.addEventListener('throttledScrollCollapse', () => {
      this.scrollCollapse();
    });
  }

  scrollCollapse() {
    this.deepCollapse();
    this.resetMobileHeader();
    this.resetDesktopHeader();
  }

  handleMobileHeader() {

    this.deepCollapse();

    if (this.mobileBP.matches) {
      this.enableMobileHeader();
      this.makeMobileHandlers();
    } else {
      this.resetMobileHeader();
    }
  }

  makeMobileHandlers() {
    let dropDownList = this.headerEl.getElementsByClassName('Header-dropDownMobile');
    let buttonList = this.headerEl.getElementsByClassName('Header-mainButtonMobile');
    let buttons = Array.from(buttonList).entries();
    let self = this;
    for (const [index, button] of buttons) {
      button.addEventListener('click', function() {
        for (let dIndex = 0; dIndex < dropDownList.length; dIndex++) {
          if (dIndex === index) {
            dropDownList[dIndex].classList.toggle('Header-dropDownMobile--isOpen');
            buttonList[dIndex].classList.toggle('Header-mainButtonMobile--isOpen');
          } else {
            dropDownList[dIndex].classList.remove('Header-dropDownMobile--isOpen');
            buttonList[dIndex].classList.remove('Header-mainButtonMobile--isOpen');
          }
          self.deepInnerCollapse(dIndex);
        }
      });

      this.makeInnerMobileHandlers(button.value);
    }
  }

  deepCollapse() {
    for (const expanded of this.headerEl.getElementsByClassName('Header-dropDownMobile--isOpen')) {
      expanded.classList.remove('Header-dropDownMobile--isOpen');
    }

    for (const expanded of this.headerEl.getElementsByClassName('Header-mainButtonMobile--isOpen')) {
      expanded.classList.remove('Header-mainButtonMobile--isOpen');
    }

    for (const expanded of this.headerEl.getElementsByClassName('Header-innerDrop--isOpen')) {
      expanded.classList.remove('Header-innerDrop--isOpen');
    }

    for (const expanded of this.headerEl.getElementsByClassName('Header-innerSubButton--isOpen')) {
      expanded.classList.remove('Header-innerSubButton--isOpen');
    }
  }

  deepInnerCollapse(index) {
    let dropDown = this.headerEl.getElementsByClassName('Header-dropDownMobile')[index];
    let innerDrops = dropDown.getElementsByClassName('Header-innerDrop--isOpen');
    let innerButtons = dropDown.getElementsByClassName('Header-innerSubButton--isOpen');

    if (innerDrops.length) {
      innerDrops[0].classList.remove('Header-innerDrop--isOpen');
      innerButtons[0].classList.remove('Header-innerSubButton--isOpen');
    }
  }

  makeInnerMobileHandlers(section) {
    let dropDownList = this.headerEl.getElementsByClassName('Header-innerDrop--' + section);
    let buttonList = this.headerEl.getElementsByClassName('Header-innerSubButton--' + section);
    let buttons = Array.from(this.headerEl.getElementsByClassName('Header-innerSubButton--' + section)).entries();
    for (const [index, button] of buttons) {
      button.addEventListener('click', function() {
        for (let dIndex = 0; dIndex < dropDownList.length; dIndex++) {
          if (dIndex === index) {
            dropDownList[dIndex].classList.toggle('Header-innerDrop--isOpen');
            buttonList[dIndex].classList.toggle('Header-innerSubButton--isOpen');
          } else {
            dropDownList[dIndex].classList.remove('Header-innerDrop--isOpen');
            buttonList[dIndex].classList.remove('Header-innerSubButton--isOpen');
          }
        }
      });
    }
  }

  makeDesktopHandlers() {
    let prevIndex = -1;
    let dropDownWrap = this.headerEl.getElementsByClassName('Header-dropDowns')[0];
    let dropDownList = this.headerEl.getElementsByClassName('Header-dropDown');
    let buttonsList = this.headerEl.getElementsByClassName('Header-mainButton');
    let buttons = Array.from(buttonsList).entries();
    for (const [index, button] of buttons) {
      if (index !== 3) { // if it isn't the link to the locator
        button.addEventListener('click', function() {
          for (let bIndex = 0; bIndex < buttonsList.length; bIndex++) {
            if (bIndex === index) {
              buttonsList[bIndex].classList.add('Header-mainButton--isOpen');
            } else {
              buttonsList[bIndex].classList.remove('Header-mainButton--isOpen');
            }
          }

          for (let dIndex = 0; dIndex < dropDownList.length; dIndex++) {
            if (dIndex === index) {
              dropDownList[dIndex].classList.add('Header-dropDown--isOpen');
            } else {
              dropDownList[dIndex].classList.remove('Header-dropDown--isOpen');
            }
          }

          if (index === prevIndex) {
            dropDownWrap.classList.toggle('Header-dropDowns--isOpen');
            buttonsList[index].classList.remove('Header-mainButton--isOpen');
          } else {
            dropDownWrap.classList.add('Header-dropDowns--isOpen');
          }
          prevIndex = index;
        });
      }
    }
  }

  resetDesktopHeader() {
    let dropDownWrap = this.headerEl.getElementsByClassName('Header-dropDowns')[0];
    let dropDownList = this.headerEl.getElementsByClassName('Header-dropDown');
    let buttonsList = this.headerEl.getElementsByClassName('Header-mainButton');

    for (let bIndex = 0; bIndex < buttonsList.length; bIndex++) {
      buttonsList[bIndex].classList.remove('Header-mainButton--isOpen');
    }

    for (let dIndex = 0; dIndex < dropDownList.length; dIndex++) {
      dropDownList[dIndex].classList.remove('Header-dropDown--isOpen');
    }

    dropDownWrap.classList.remove('Header-dropDowns--isOpen');
  }

  enableMobileHeader() {
    const headerLogic = () => {
      this.menuOverlayEl.style['display'] = 'block';

      this.deepCollapse();
      if (this.headerEl.classList.toggle('isOpen')) {
        this.navEl.style['max-height'] = `300px`;
        this.navEl.style['height'] = 'auto';
        this.menuOverlayEl.style['opacity'] = '1';
        window.setTimeout(() => {
          this.navEl.style['max-height'] = `100vh`;
        }, 300); // matches height transition time
        this.focusTrap.activate();
      } else {
        this.navEl.style['max-height'] = '0';
        this.menuOverlayEl.style['opacity'] = '0';
        window.setTimeout(() => {
          this.menuOverlayEl.style['display'] = 'none';
        }, 300); // matches opacity transition time
        this.focusTrap.deactivate();
      }

      accessibilityHelpersInstance.toggleAriaState(this.navEl, 'hidden');
      accessibilityHelpersInstance.toggleAriaState(this.menuBtnEl, 'expanded');
      accessibilityHelpersInstance.toggleAriaState(this.menuOverlayEl, 'expanded');
      accessibilityHelpersInstance.toggleAriaState(this.menuOverlayEl, 'hidden');
    };

    this.handleHeaderTabIndexes(-1);
    accessibilityHelpersInstance.setAriaProp(this.navEl, 'hidden', true);

    if (!this.hasBeenSetupMobile) {
      this.menuBtnEl.addEventListener('click', headerLogic);
      this.menuOverlayEl.addEventListener('click', headerLogic);
      this.hasBeenSetupMobile = true;
    }
    this.wasDesktop = false;
  }

  resetMobileHeader() {
    this.headerEl.classList.remove('isOpen');
    this.navEl.style = '';
    accessibilityHelpersInstance.setAriaProp(this.navEl, 'hidden', false);
    this.focusTrap.deactivate();
    this.handleHeaderTabIndexes(0);
    this.wasDesktop = true;
  }

  handleHeaderTabIndexes(tabIndex) {
    accessibilityHelpersInstance.setTabIndex(this.menuItems, tabIndex);
    accessibilityHelpersInstance.setTabIndex(this.menuOverlayEl, tabIndex);
  }
}
